const LANGUAGE = {
  设置标题: 'title',
        正文: 'p',
        链接文字: 'link text',
        链接: 'link',
        上传图片: 'upload image',
        上传: 'upload',
        创建: 'init',
}

export default LANGUAGE